const Linkdata = [
  { text: "Home", path: "/home" },
  { text: "service", path: "/service" },
  { text: "WorkShop", path: "/workShop" },
  { text: "Resources", path: "/shop" },
  { text: "Portfolio", path: "/portfolio" },
  { text: "Contact", path: "/contact" },
];

export default Linkdata;
