import React from "react";
import { ReactComponent as YourSvgComponent } from "../media/8497407_3908918.svg";
function Svg6() {
  return (
    <div className="div">
      <YourSvgComponent />
    </div>
  );
}

export default Svg6;
