import image1 from "../media/shopmedia/product1/colorfull illusrative trees-01.jpg";
import image2 from "../media/shopmedia/product1/colorfull illusrative trees-02.jpg";
import image3 from "../media/shopmedia/product2/Screenshot 2024-01-12 093619.png";
import image4 from "../media/shopmedia/product3/abstract_trees.jpg";
import image5 from "../media/shopmedia/pro4/ARCHITECTURE STUDENT'S CAD BLOCK STARTER PACK FULL.jpg";
import image6 from "../media/shopmedia/pro4/ARCHITECTURE STUDENT'S CAD BLOCK STARTER PACK.jpg";
import image7 from "../media/shopmedia/pro5/iPad Mockup  square.jpg";
import image8 from "../media/shopmedia/pro5/sketchy human figures.jpg";
import image9 from "../media/shopmedia/pro6/DECOR ITEMS ELEVATION  VIEW MOCKUPS.jpg";
import image10 from "../media/shopmedia/pro6/pack promo pdf (1)1.jpg";
import image11 from "../media/shopmedia/pro6/pack promo pdf (2) 2.jpg";
import image12 from "../media/shopmedia/pro6/SOFA & ARMCHAIRS ELEVATION VIEW MOCKUPS.jpg";
import image13 from "../media/shopmedia/pro6/SOFA & ARMCHAIRS ISO VIEW MOCKUPS.jpg";
import image14 from "../media/shopmedia/pro6/SOFA & ARMCHAIRS TOP VIEW MOCKUPS.jpg";
import image15 from "../media/shopmedia/pro6/TABLES AND LAMPS ISO VIEW MOCKUPS.jpg";
import image16 from "../media/shopmedia/pro6/TV CABINETS ISO VIEW MOCKUPS.jpg";
import image17 from "../media/shopmedia/pro7/watercolour_trees .jpg";
import image18 from "../media/shopmedia/pro7/water colour trees Mockup 2.jpg";
import image19 from "../media/shopmedia/pro7/water colour trees Mockup 4.jpg";
import image20 from "../media/shopmedia/pro7/water colour trees Mockup 5.jpg";
import image21 from "../media/shopmedia/pro7/water colour trees Mockup final.jpg";
import image22 from "../media/shopmedia/pro8/sketchy trees 2.jpg";
import image23 from "../media/shopmedia/pro9/Artboard 1 copyldpi.jpg";
import image24 from "../media/shopmedia/pro9/Artboard 1ldpi.jpg";
import image25 from "../media/shopmedia/pro9/Artboard 2 copy 2ldpi.jpg";
import image26 from "../media/shopmedia/pro9/Artboard 2 copy 4ldpi.jpg";
import image27 from "../media/shopmedia/pro10/CONCEPTUAL ZONING ESSENTIALS MEGA PACK.jpg";
import image28 from "../media/shopmedia/pro10/zji67gx5vsolkxo3yoo7wl21bmjk.webp";
import image29 from "../media/shopmedia/pro11/Artboard 1 copyldpi.jpg";
import image30 from "../media/shopmedia/pro11/Artboard 1ldpi.jpg";
import image31 from "../media/shopmedia/pro12/sketchy trees and human figures.jpg";
import image32 from "../media/shopmedia/pro13/indian peeps dwg jpeg.jpg";
import image33 from "../media/shopmedia/pro13/indian peeps.jpg";
import image34 from "../media/shopmedia/pro13/The indian peeps b&w.jpg";
import image35 from "../media/shopmedia/pro14/minimalistic trees 3.jpg";
import image36 from "../media/shopmedia/pro15/SITE ANALYSIS ESSENTIALS.jpg";
import image37 from "../media/shopmedia/pro16/2 pattern swatches by studio_articulation.jpg";
import image38 from "../media/shopmedia/pro17/patter swatches by studio_articulation FOR GUMROAD.jpg";
import image39 from "../media/shopmedia/pro18/Artboard 1 copyldpi.jpg";
import image40 from "../media/shopmedia/pro18/Artboard 1ldpi.jpg";
import image41 from "../media/shopmedia/pro19/1 vector human figures(girl gang).jpg";
import image42 from "../media/shopmedia/pro20/minimalistic trees pack 2.jpg";
import image43 from "../media/shopmedia/pro20/VECTOR MINIMALISTIC PACK 2.jpg";
import image44 from "../media/shopmedia/pro21/Artboard 1 copyldpi 2.jpg";
import image45 from "../media/shopmedia/pro21/Artboard 1 copyldpi.jpg";
import image46 from "../media/shopmedia/pro21/Artboard 1ldpi 22.jpg";
import image47 from "../media/shopmedia/pro21/Artboard 1ldpi.jpg";
import image48 from "../media/shopmedia/pro22/FRONT PAGE INSTA POST 3.jpg";
import image49 from "../media/shopmedia/pro22/FRONT PAGE INSTA POST.jpg";
import image50 from "../media/shopmedia/pro22/FRONT PAGE INSTA POST2.jpg";
import image51 from "../media/shopmedia/pro23/TREE SILHOUETTE 2 colourful .jpg";
import image52 from "../media/shopmedia/pro23/TREE SILHOUETTE 2.jpg";
import image53 from "../media/shopmedia/pro23/TREE SILHOUETTES 2 PACKAGING.jpg";
import image54 from "../media/shopmedia/pro24/annotation.jpg";
import image55 from "../media/shopmedia/pro24/architecture diagram symbol amd annotations.jpg";
import image56 from "../media/shopmedia/pro25/DRY TREES SILHOUETTE packaging.jpg";
import image57 from "../media/shopmedia/pro25/vutysb10yjlqzpki7sp8exf7k5zn.jpg";
import image58 from "../media/shopmedia/pro26/2-box-packaging-mockup-01.jpg";
import image59 from "../media/shopmedia/pro26/9j5vq8dahfhjy1vj42rsvd33gxax.webp";
import image60 from "../media/shopmedia/pro27/dsd.jpg";
import image61 from "../media/shopmedia/pro27/xa228x3wnlu6v3llf5kwpfmq402k.jpg";
import image62 from "../media/shopmedia/pro28/TEXTURE PACK box-packaging-mockup-04.jpg";
import image63 from "../media/shopmedia/pro29/north symbol packaging.jpg";
import image64 from "../media/shopmedia/pro29/ntkbm0wyvynvaal5qe3biryjsh83.jpg";
import image65 from "../media/shopmedia/pro30/j8q3hmpymrvzo25ybzieg1i3bp8i.webp";
import image66 from "../media/shopmedia/pro30/TREE SILHOUETTES VOL.1.jpg";
import image67 from "../media/shopmedia/pro31/HANDMADE TREES CUTOUT package mockup.jpg";
import image68 from "../media/shopmedia/pro31/handmade trees package.jpg";
import image69 from "../media/shopmedia/pro32/botnic plant brush preset.jpg";
import image70 from "../media/shopmedia/pro32/BOTNICAL PLANT BRUSH PRESET package mockup.jpg";
import image71 from "../media/shopmedia/pro33/people in offices 3.jpg";
import image72 from "../media/shopmedia/pro33/people in offices mokeup.jpg";
import image73 from "../media/shopmedia/pro34/Postal package mockup.jpg";
import image74 from "../media/shopmedia/pro34/MINIMALISTIC TREES 2.jpg";
import image75 from "../media/shopmedia/pro34/MINIMALISTIC TREES.jpg";
import image76 from "../media/shopmedia/pro35/IMG_0791.jpeg";
import image77 from "../media/shopmedia/pro35/IMG_0792.jpeg";
import image78 from "../media/shopmedia/pro36/IMG_0796.jpeg";
import image79 from "../media/shopmedia/pro37/site plan essentials.jpg";
import image80 from "../media/shopmedia/pro38/Casual people.png";
import image81 from "../media/shopmedia/pro39/IMG_1039.jpeg";
import image82 from "../media/shopmedia/pro39/IMG_1043.jpeg";
import image83 from "../media/shopmedia/pro 40/IMG_1086.jpeg";
import image84 from "../media/shopmedia/pro 40/IMG_1088.jpeg";
const Shopdata = [
  {
    name: "Colorful illustrative trees pack 1",
    price: "$3",
    size: "40.7 MB",
    description: (
      <>
        We are introducing our Vector-based colorful ILLUSTRATIVE TREE PACK,
        designed specifically for architecture students and related disciplines.
        This resource pack is an invaluable asset that offers numerous benefits
        and enhances the creative process in architectural design. The
        vector-based TREES Pack empowers architecture students to elevate their
        design presentations, foster creativity, and develop a strong visual
        language. It offers convenience, consistency, and a rich array of design
        possibilities, making it an essential tool for those passionate about
        architectural illustration and creating impactful designs.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes in the following file format.
        <br />
        AI FILE
        <br />
        JPEG FILE
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    link: "https://studioarticulation.gumroad.com/l/gepai?layout=profile",
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image1, image2],
  },
  {
    name: "MEGA TEXTURE PACK",
    price: "$5",
    size: "",
    description: (
      <>
        Introducing the Mega Texture Pack – a game-changing digital product that
        promises to revolutionize the way content creators approach
        post-production. This comprehensive collection comprises over 100+
        high-quality textures, spanning a wide variety of materials, from the
        serene flow of water to the rugged charm of bricks, designed to elevate
        and enhance your projects in unimaginable ways.
        <br />
        The Mega Texture Pack is not just a product; it's a creative powerhouse.
        It empowers content creators, designers, and artists to bring their
        visions to life with unparalleled realism and versatility. With over
        100+ high-quality textures at your fingertips, you're no longer limited
        by the constraints of resource availability. Elevate your
        post-production processes and redefine what's possible with digital
        content—unleash your creativity with the Mega Texture Pack today!
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        we can repost your fantastic work.
        <br />
        @studio_articulation
        <br />
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes following file format
        <br />
        JPEG FILES
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    link: "https://studioarticulation.gumroad.com/l/tsrxa?layout=profile",
    category: "Texture",
    filetype: "ZIP file",
    images: [image3, image3],
  },
  {
    name: "abstract trees",
    price: "$1",
    size: "21.8 MB",
    description: (
      <>
        ABSTRACT TREES will help generate a sense of scale in the drawing and
        make them aesthetically pleasing.
        <br />
        This pack contains 15+ different ai vector-based trees. These vector
        figures will help you create amazing Post-digital images!
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        we can repost your fantastic work.
        <br />
        @studio_articulation
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        PDF file
        <br />
        png file
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    link: "https://studioarticulation.gumroad.com/l/zsabq?layout=profile",
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image4, image4],
  },
  {
    name: "ARCHITECTURE STUDENT'S CAD BLOCK STARTER PACK",
    price: "$5",
    size: "38.3 MB",
    description: (
      <>
        PRESENTING: THE ULTIMATE ARCHITECTURAL CAD BLOCK STARTER PACK!
        <br />
        This comprehensive package is meticulously designed to cater to every
        need of architecture students and professionals alike. Say goodbye to
        the hassle of searching for individual components; this all-inclusive
        bundle is your one-stop solution. Inside, you'll discover an array of
        indispensable resources, including pattern swatches, lifelike human
        figures, diverse transportation depictions, and an assortment of daily
        essentials.
        <br />
        Crafted with precision and consideration, this architecture student's
        CAD block starter pack guarantees seamless integration into your design
        projects. Its meticulously curated contents ensure that you have
        everything you require at your fingertips. Whether you're a budding
        architect embarking on your academic journey or a seasoned professional
        shaping the world with your designs, this pack is tailored to elevate
        your creative process.
        <br />
        Empower your architectural visions with the convenience and versatility
        of this all-encompassing CAD block starter pack. Streamline your
        workflow and bring your ideas to life effortlessly. It's more than a
        collection – it's a tool that enhances your efficiency and creativity in
        the realm of architectural design.
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        we can repost your fantastic work.
        <br />
        @studio_articulation
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        PDF FILE
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    link: "https://studioarticulation.gumroad.com/l/veosh?layout=profile",
    category: "CAD Blocks",
    filetype: "ZIP file",
    images: [image6, image5],
  },
  {
    name: "SKETCHY HUMANS",
    price: "$1",
    size: "24.8 MB",
    link: "https://studioarticulation.gumroad.com/l/npnde?layout=profile",
    description: (
      <>
        SKETCHY HUMANS will help generate a sense of scale in the drawing and
        make them aesthetically pleasing.
        <br />
        This pack contains 25+ different ai vector-based human figures. These
        vector figures will help you create amazing Post-digital images!
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        we can repost your fantastic work.
        <br />
        @studio_articulation
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        PDF FILE
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Human",
    filetype: "ZIP file",
    images: [image7, image8],
  },
  {
    name: "ALL IN ONE LIVING ROOM FURNITURE PACK",
    price: "$10",
    size: "409 MB",
    description: (
      <>
        YOU DON'T NEED TO SEARCH ANYTHING NOW!!!!! CAUSE WE ARE INTRODUCING OUR
        BRAND NEW PRODUCT
        <br />
        'ALL IN ONE - LIVING ROOM PACK', encompassing a comprehensive collection
        of 2D and 3D drawings. This extraordinary bundle comprises 97 distinct
        blocks in plan, elevation, isometric (from two angles), and a 3D model.
        A total of 1033 assets.
        <br />
        The Living Room Pack, a compilation of 97 blocks (1033 assets) neatly
        categorized as follows:
        <br />
        1. Sofas
        <br />
        2. Armchair
        <br />
        3. Console tables
        <br />
        4. Coffee table
        <br />
        5. Side table
        <br />
        6. Ottoman
        <br />
        7. Table lamps
        <br />
        8. Floor lamps
        <br />
        9. Flowers plants
        <br />
        10. Pendant lights
        <br />
        11. Rugs
        <br />
        12. Curtains
        <br />
        13. Decor ~ books, vases
        <br />
        These all are in Illustrator files, PNG files, CAD blocks, and a 3D
        model, ensuring you have a comprehensive range of resources.
        <br />
        This exceptional pack enables you to create drawings of various
        perspectives and styles without limitations.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes following file format.
        <br />
        SKP FILE (SKETCHUP FILE)
        <br />
        AI FILE (ADOBE ILLUSTRATOR FILE)
        <br />
        DWG FILE (AUTOCAD FILE)
        <br />
        JPEG FILE
        <br />
        PNG FILE
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    link: "https://studioarticulation.gumroad.com/l/istdx?layout=profile",
    category: "Furniture",
    filetype: "ZIP file",
    images: [
      image15,
      image16,
      image9,
      image10,
      image11,
      image12,
      image13,
      image14,
      image14,
    ],
  },
  {
    name: "watercolor vector trees pack",
    price: "$2",
    size: "8.38 MB",
    description: (
      <>
        Introducing our Vector-based WATERCOLOR TREES Pack, designed
        specifically for architecture students and related disciplines. This
        resource pack is an invaluable asset that offers numerous benefits and
        enhances the creative process in architectural design. the Vector-based
        WATERCOLOR TREES Pack empowers architecture students to elevate their
        design presentations, foster creativity, and develop a strong visual
        language. It offers convenience, consistency, and a rich array of design
        possibilities, making it an essential tool for those passionate about
        architectural illustration and creating impactful designs.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes following file format.
        <br />
        AI FILE
        <br />
        JPEG FILE
        <br />
        PNG FILE
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    link: "https://studioarticulation.gumroad.com/l/ngoea?layout=profile",
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image17, image18, image19, image20, image21],
  },
  {
    name: "Sketchy trees 2",
    price: "$2",
    size: "17.7 MB",
    link: "https://studioarticulation.gumroad.com/l/koqod?layout=profile",
    description: (
      <>
        Sketchy Trees 2 will help generate a sense of scale in the drawing and
        make them aesthetically pleasing.
        <br />
        his pack contains 19 different ai vector-based trees. These vector
        figures will help you create amazing Post-digital images! and ABR file
        (photoshop brushes)
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        we can repost your fantastic work.
        <br />
        @studio_articulation
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        ABR FILE (PHOTOSHOP BRUSHES)
        <br />
        PDF FILE
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image22, image22],
  },
  {
    name: "HANDMADE VECTOR INDOOR PLANTS",
    price: "$3",
    size: "103 MB",
    link: "https://studioarticulation.gumroad.com/l/zzonxr?layout=profile",
    description: (
      <>
        Introducing our Vector-based Indoor Plant Illustrator Resource Pack,
        designed specifically for architecture students and related disciplines.
        This resource pack is an invaluable asset that offers numerous benefits
        and enhances the creative process in architectural design. the
        Vector-based Indoor Plant Illustrator Resource Pack empowers
        architecture students to elevate their design presentations, foster
        creativity, and develop a strong visual language. It offers convenience,
        consistency, and a rich array of design possibilities, making it an
        essential tool for those passionate about architectural illustration and
        creating impactful designs.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes following file format.
        <br />
        AI FILE
        <br />
        JPEG FILE
        <br />
        PNG FILE
        <br />
        PDF FILE
        <br />
        SVG FILE
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image23, image24, image25, image26],
  },
  {
    name: "CONCEPTUAL ZONING ESSENTIAL - Mega pack",
    price: "$5",
    size: "",
    description: (
      <>
        I have created a comprehensive digital resource specifically designed
        for architecture students that focuses on the essential elements of site
        zoning diagrams. This resource covers various aspects such as arrows,
        trees, annotations, north indicators, icons, logos, and more.
        <br />
        By leveraging this digital resource, architecture students will have
        access to a wide range of symbols, elements, and annotations, enabling
        them to create visually compelling and informative site zoning diagrams.
        This resource not only enhances their diagramming skills but also
        facilitates effective communication of their design concepts and site
        analysis to both peers and professionals in the field.
        <br />
        this pack contains an AI file and JPEG of more than 100+ architecture
        annotations and symbols.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        b/w colour palette
        <br />
        architects- font
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    link: "https://studioarticulation.gumroad.com/l/wygnas?layout=profile",
    category: "Project Resource Files",
    filetype: "ZIP file",
    images: [image27, image28],
  },
  {
    name: "HUMANS IN TOP VIEW",
    price: "$3",
    size: "14.5 MB",
    description: (
      <>
        add our new 'HUMANS IN TOP VIEW' in your library.
        <br />
        Vector human figures help in generating a sense of scale in the drawing
        and making them aesthetically pleasing.
        <br />
        his pack contains 50 different ai vector-based human figures. These
        vector figures will help you create amazing Post-digital images!
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        that we can repost your amazing work.
        <br />
        @studio_articulation
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Human",
    filetype: "ZIP file",
    images: [image30, image29],
    link: "https://studioarticulation.gumroad.com/l/fyiks?layout=profile",
  },
  {
    name: "Sketchy trees and human figure",
    price: "0$",
    size: "2.88 MB",
    link: "https://studioarticulation.gumroad.com/l/rjmcb?layout=profile",
    description: (
      <>
        Sketchy trees and human figures will help generate a sense of scale in
        the drawing and make them aesthetically pleasing.
        <br />
        his pack contains 15 different ai vector-based human figures. These
        vector figures will help you create amazing Post-digital images!
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        we can repost your fantastic work.
        <br />
        @studio_articulation
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image31, image31],
  },
  {
    name: "THE INDIAN PEEPS ( flat vector human figures)",
    price: "$2",
    size: "4.94 MB",
    description: (
      <>
        add our new 'THE INDIAN PEEPS' in your library.
        <br />
        Vector human figures help in generating a sense of scale in the drawing
        and making them aesthetically pleasing.
        <br />
        his pack contains 15 different ai vector-based human figures. These
        vector figures will help you create amazing Post-digital images!
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        that we can repost your amazing work.
        <br />
        @studio_articulation
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        CAD file
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    link: "https://studioarticulation.gumroad.com/l/atnkv?layout=profile",
    category: "Human",
    filetype: "ZIP file",
    images: [image33, image34, image32],
  },
  {
    name: "VECTOR MINIMALISTIC TREES PACK 3",
    price: "$2",
    size: "8.68 MB",
    link: "https://studioarticulation.gumroad.com/l/gsyma?layout=profile",
    description: (
      <>
        add our new vector-based minimalistic trees pack 3'in your library.
        <br />
        it helps add a sense of scale in any type of architectural illustration,
        elevation, section, diagram, model, etc
        <br />
        this pack contains an ai file and jpegs of more than 15+ vector trees.
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image35, image35],
  },
  {
    name: "SITE ANALYSIS ESSENTIALS MEGA PACK",
    price: "$5",
    size: "5.14 MB",
    description: (
      <>
        In the field of architecture, site analysis is an essential component of
        the design process. Understanding the topography, environment, and
        existing conditions of a site is critical in creating a successful
        design that fits the needs of the client and meets the constraints of
        the location.
        <br />
        To aid in this process, a digital product that utilizes annotation,
        signs, and symbols can be a valuable tool for architects and designers.
        This product would consist of different types of vegetation, sun paths,
        arrows, icons, scales, and color palettes which allow you to present
        your work more effectively in your next site analysis presentation.
        <br />
        Overall, a digital product that utilizes annotation, signs, and symbols
        for site analysis in architecture would be a valuable tool for designers
        and architects. By providing a way to document and analyze site
        conditions in a clear and organized way, this product could streamline
        the design process and lead to more successful outcomes for clients and
        users.
        <br />
        a pack containing an AI file and JPEG of more than 100+ architecture
        annotations and symbols.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    link: "https://studioarticulation.gumroad.com/l/vrnqbd?layout=profile",
    category: "Project Resource Files",
    filetype: "ZIP file",
    images: [image36, image36],
  },
  {
    name: "2 PATTERN SWATCHES for illustrator",
    price: "$2",
    link: "https://studioarticulation.gumroad.com/l/cknoy?layout=profile",
    size: "8.01 MB",
    description: (
      <>
        add our new pattern swatches to your library.
        <br />
        this pack contains 36 different ai vector pattern swatches. These vector
        pattern swatches will help you create amazing Post-digital images,
        AutoCAD plan renders, elevation and section renders, etc.
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        that we can repost your amazing work.
        <br />
        @studio_articulation
        <br />
        imp: just delete the black box outside the swatches and drag them to
        your illustrator swatches panel and you are ready to add the pattern
        anywhere in any shape.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Patterns",
    filetype: "ZIP file",
    images: [image37, image37],
  },
  {
    name: "PATTERN SWATCHES 1",
    link: "https://studioarticulation.gumroad.com/l/pyfzi?layout=profile",
    price: "$1",
    size: "3.38 MB",
    description: (
      <>
        Add our new pattern swatches to your library.
        <br />
        this pack contains 16 different ai vector pattern swatches. These vector
        pattern swatches will help you create amazing Post-digital images,
        AutoCAD plan renders, elevation and section renders, etc.
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        that we can repost your amazing work.
        <br />
        @studio_articulation
        <br />
        imp: just delete the black box outside the swatches and drag them to
        your illustrator swatches panel and you are ready to add the pattern
        anywhere in any shape.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Patterns",
    filetype: "ZIP file",
    images: [image38, image38],
  },
  {
    name: "Abstract hoomans pack",
    price: "$3",
    link: "https://studioarticulation.gumroad.com/l/sfowe?layout=profile",
    size: "4.97 MB",
    description: (
      <>
        add our new 'Abstract hoomans' in your library.
        <br />
        Vector human figures help in generating a sense of scale in the drawing
        and making them aesthetically pleasing.
        <br />
        his pack contains 25+ different ai vector-based human figures. These
        vector figures will help you create amazing Post-digital images!
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        that we can repost your amazing work.
        <br />
        @studio_articulation
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        CAD file
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Human",
    filetype: "ZIP file",
    images: [image40, image39],
  },
  {
    name: "Vector human figures (girl gang)",
    price: "$4",
    link: "https://studioarticulation.gumroad.com/l/nucoc?layout=profile",
    size: "11.4 MB",
    description: (
      <>
        add our new 'Vector human figures(girl gang)' in your library.
        <br />
        Vector human figures help in generating a sense of scale in the drawing
        and making them aesthetically pleasing.
        <br />
        his pack contains 8 different ai vector-based human figures. These
        vector figures will help you create amazing Post-digital images!
        <br />
        Enjoy! And don't forget to tag us on Instagram whenever you use them so
        that we can repost your amazing work.
        <br />
        @studio_articulation
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Human",
    filetype: "ZIP file",
    images: [image41, image41],
  },
  {
    name: "Vector minimalistic trees pack 2",
    price: "$5",
    link: "https://studioarticulation.gumroad.com/l/zqgrf?layout=profile",
    size: "1.74 MB",
    description: (
      <>
        add our new vector-based minimalistic trees pack 2'in your library.
        <br />
        it helps in adding a sense of scale in any type of architectural
        illustration, elevation, section, diagram, model, etc
        <br />
        this pack had contain an ai file, and jpegs of more than 25+ vector
        trees.
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image42, image43],
  },
  {
    name: "architecture diagram- annotations and symbols 2",
    price: "$3",
    link: "https://studioarticulation.gumroad.com/l/etcew?layout=profile",
    size: "12.3 MB",
    description: (
      <>
        Add our new architecture diagram - annotation and symbols' +
        architecture diagram - annotation and symbols'2 in your library.
        <br />
        SYMBOLS AND ANNOTATIONS helps in presenting site analysis and concept
        level diagrams in easy, better and attractive way.
        <br />
        we present you pack of different types of arrows,sunpath diagrams,
        connecting lines, arrows and colour palettes.
        <br />
        this pack had contain ai file, jpegs of more than 30 architecture
        annotation and symbols.
        <br />
        the pack comes following file format.
        <br />
        2 AI file
        <br />
        2 JPEG file
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Symbols & Annotations",
    filetype: "ZIP file",
    images: [image44, image45, image46, image47],
  },
  {
    name: "MINIMALISTIC PORTFOLIO FRONT PAGES",
    price: "$3",
    size: "47.3 MB",
    link: "https://studioarticulation.gumroad.com/l/kildy?layout=profile",
    description: (
      <>
        Introducing minimalistic, clean, a3 professional architecture portfolio
        front pages with free font styles.
        <br />
        compatible with Photoshop, the pack contain editable PSD file with jpegs
        and font styles.
        <br />
        extract the ZIP file with WinRAR to the folder.
        <br />
        for printing export pdf print
        <br />
        for digital printing use pdf interactive.
        <br />
        the pack comes with following file format.
        <br />
        PSD file
        <br />
        JPEG
        <br />
        FREE FONT STYLES
        <br />
        thank you for supporting the platform @studio_articulation
        <br />
        note: while using our pack you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Project Resource Files",
    filetype: "ZIP file",
    images: [image48, image49, image50],
  },
  {
    name: "TREE SILHOUETTES 2",
    price: "$3",
    link: "https://studioarticulation.gumroad.com/l/irsrx?layout=profile",
    size: "11.5 MB",
    description: (
      <>
        add another human brush preset in your library the ' TREE SILHOUETTES 2'
        <br />
        brushes drastically improve your workflow and enhance the aesthetics of
        your presentation and generate a sense of scale with just one click.
        <br />
        this pack had contain more than 20+ tree silhouette brush preset ABR
        files [compatible with photoshop]
        <br />
        the pack comes following file format.
        <br />
        ABR file
        <br />
        PNG file
        <br />
        JPEG
        <br />
        file thank you for supporting the platform @studio_articulation
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image51, image52, image53],
  },
  {
    name: "architecture diagram - symbols and annotations",
    price: "$3",
    size: "7.81 MB",
    link: "https://studioarticulation.gumroad.com/l/ewqxd?layout=profile",
    description: (
      <>
        add our new architecture diagram - annotation and symbols' in your
        library.
        <br />
        SYMBOLS AND ANNOTATIONS helps in presenting site analysis and concept
        level diagrams in easy, better and attractive way.
        <br />
        we present you pack of different types of arrows,sunpath diagrams,
        connecting lines, arrows and colour palettes.
        <br />
        this pack had contain ai file, jpegs of more than 30 architecture
        annotation and symbols.
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Symbols & Annotations",
    filetype: "ZIP file",
    images: [image54, image55],
  },
  {
    name: "dry trees silhouette brush preset",
    price: "$3",
    link: "https://studioarticulation.gumroad.com/l/uevjq?layout=profile",
    size: "4.25 MB",
    description: (
      <>
        add another human brush preset in your library the ' dry trees
        silhouette'
        <br />
        brushes drastically improve your workflow and enhance the aesthetics of
        your presentation and generate a sense of scale with just one click.
        <br />
        this pack had contain more than 20 human figure brush preset ABR files
        (compatible with photoshop )
        <br />
        the pack comes following file format.
        <br />
        ABR file
        <br />
        PNG file
        <br />
        JPEG file
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image56, image57],
  },
  {
    name: "Handmade tree {plan} volume 2",
    price: "$5",
    size: "",
    link: "https://studioarticulation.gumroad.com/l/jclvn?layout=profile",
    description: (
      <>
        ALL NEW handmade watercolour trees in plan cutout pack volume 2 for
        architecture visualization and presentation.
        <br />
        which can be used in architecture illustrations and photoshop elevation
        renders .
        <br />
        In this pack you will get 20 high quality trees in a psd , png and pdf
        format.
        <br />
        the pack comes following file format.
        <br />
        PSD file
        <br />
        JPEG file
        <br />
        PNG file
        <br />
        PDF file thank you for supporting the platform @studio_articulationnote:
        while using our preset you can give credit to articulation. if you
        publish your work on Instagram or LinkedIn please tag us at
        @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image58, image59],
  },
  {
    name: "sanna people {human scale brush preset}",
    price: "$3",
    link: "https://studioarticulation.gumroad.com/l/gunsyo?layout=profile",
    size: "340 KB",
    description: (
      <>
        add another human brush preset in your library the ' SANNA PEOPLE'
        <br />
        brushes drastically improve your workflow and enhance the aesthetics of
        your presentation and generate a sense of scale with just one click.
        <br />
        this pack had contain more than 25 human figure brush preset ABR files
        (compatible with photoshop)
        <br />
        the pack comes following file format.
        <br />
        ABR file
        <br />
        PNG file
        <br />
        JPEG file
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Human",
    filetype: "ZIP file",
    images: [image60, image61],
  },
  {
    name: "TEXTURE PACK VOL.1",
    price: "$0",
    size: "3.69 MB",
    link: "https://studioarticulation.gumroad.com/l/igngd?layout=profile",
    description: (
      <>
        this pack had contain 15 high-quality texture jpeg
        <br />
        the pack comes following file format.
        <br />
        JPEG file
        <br />
        thank you for supporting our platform @studio_articulation
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at
        @studio_articulation.
      </>
    ),
    category: "Texture",
    filetype: "ZIP file",
    images: [image62, image62],
  },
  {
    name: "NORTH SYMBOLS",
    price: "$1",
    size: "814 KB",
    link: "https://studioarticulation.gumroad.com/l/ipesxv?layout=profile",
    description: (
      <>
        add another human brush preset in your library the ' NORTH SYMBOLS '
        <br />
        brushes drastically improve your workflow and enhance the aesthetics of
        your presentation and generate a sense of scale with just one click.
        <br />
        this pack had contain more than 20 NORTH SYMBOL brush preset ABR files
        [compatible with photoshop]
        <br />
        the pack comes following file format.
        <br />
        ABR file
        <br />
        PNG file
        <br />
        PDF file
        <br />
        JPEG file
        <br />
        thank you for supporting our platform @studio_articulation
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at
        @studio_articulation.
      </>
    ),
    category: "Symbols & Annotations",
    filetype: "ZIP file",
    images: [image63, image64],
  },
  {
    name: "TREE SILHOUETTES brush preset",
    price: "$2",
    size: "",
    link: "https://studioarticulation.gumroad.com/l/vrtrd?layout=profile",
    description: (
      <>
        Add another human brush preset in your library the ' TREE SILHOUETTES '
        <br />
        brushes drastically improve your workflow and enhance the aesthetics of
        your presentation and generate a sense of scale with just one click.
        <br />
        this pack had contain more than 20+ tree silhouette brush preset ABR
        files [compatible with photoshop]
        <br />
        the pack comes following file format.
        <br />
        ABR file
        <br />
        PNG file
        <br />
        PDF file
        <br />
        JPEG
        <br />
        file thank you for supporting the platform @studio_articulation
        <br />
        note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image66, image65],
  },
  {
    name: "HANDMADE WATERCOLOUR TREES ( cutout pack )",
    price: "$2",
    link: "https://studioarticulation.gumroad.com/l/nqsua?layout=profile",
    size: "9 Items",
    description: (
      <>
        ALL NEW handmade watercolour trees cutout pack for architecture
        visualization and presentation.
        <br />
        which can be used in architecture illustrations and photoshop elevation
        renders .
        <br />
        this pack contains high-quality 9 trees cutouts PSD, PNG, PDF, and JPEG
        files
        <br />
        the pack comes following file format.
        <br />
        PSD file
        <br />
        JPEG file
        <br />
        PNG file
        <br />
        PDF file
        <br />
        thank you for supporting the platform @studio_articulation
        <br />
        note: while using our preset you can give credit to articulation. if you
        publish your work on Instagram or LinkedIn please tag us at
        @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image67, image68],
  },
  {
    name: "botanical plant brush preset",
    price: "$1",
    link: "https://studioarticulation.gumroad.com/l/bgnfk?layout=profile",
    size: "15+ items",
    description: (
      <>
        add another human brush preset in your library the ' botanical plants
        brush preset '
        <br />
        brushes drastically improve your workflow and enhance the aesthetics of
        your presentation and generate a sense of scale with just one click.
        <br />
        this pack contain more than 15+ plant brush preset ABR files [compatible
        with photoshop]
        <br />
        the pack comes following file format.
        <br />
        ABR file
        <br />
        PSD file
        <br />
        JPEG file
        <br />
        thank you for supporting the platform @articulation_21
        <br />
        note: while using our preset you can give credit to articulation_21. if
        you publish your work on Instagram or LinkedIn please tag us at
        @articulation_21
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image70, image69],
  },
  {
    name: "people in offices { human figure brush preset }",
    price: "$0",
    size: "20+ Items",
    link: "https://studioarticulation.gumroad.com/l/pqzdq?layout=profile",
    description: (
      <>
        add another human brush preset in your library the ' people in offices '
        <br />
        brushes drastically improve your workflow and enhance the aesthetics of
        your presentation and generate a sense of scale with just one click.
        <br />
        this pack had contain more than 20+ human figure brush preset ABR files
        [compatible with photoshop]
        <br />
        the pack comes following file format.
        <br />
        ABR file
        <br />
        PNG file
        <br />
        PDF file
        <br />
        JPEG file thank you for supporting the platform @studio_articulation
        <br />
        note: while using our preset you can give credit to studio_articulation.
        if you publish your work on Instagram or LinkedIn please tag us at
        @studio_articulation
      </>
    ),
    category: "Abr Brushes",
    filetype: "ZIP file",
    images: [image72, image71],
  },
  {
    name: "MINIMALISTIC TREE PACKAGE",
    price: "$150",
    link: "https://studioarticulation.gumroad.com/l/otddk?layout=profile",
    size: "20+ Items",
    description: (
      <>
        THE MINIMALISTIC TREES PACKAGE FOR ARCHITECTURE VISUALISATION AND
        PRESENTATION.
        <br />
        THE PACKAGE CONTAINS MORE THAN 20+ HIGH-QUALITY TREES ILLUSTRATIONS.
        <br />
        FILE TYPe - AI, PDF,PNG
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image73, image74, image75],
  },
  {
    name: "ARCHITECTURE DIAGRAM ESSENTIALS",
    price: "$8",
    link: "https://studioarticulation.gumroad.com/l/nqtfz?layout=profile",
    size: "77.8 MB",
    description: (
      <>
        In the field of architecture, architecture diagramming is an essential
        part of the design process. Understanding the topography, environment,
        and existing conditions of a site is critical in creating a successful
        design that fits the needs of the client and meets the constraints of
        the location.
        <br />
        To aid in this process, a digital product that utilizes annotation,
        signs, and symbols can be a valuable tool for architects and designers.
        This product would consist of different types of vegetation, sun paths,
        arrows, icons, scales, and color palettes, abr brushes, texture which
        allow you to present your work more effectively in your next site
        analysis presentation.
        <br />
        Overall, a digital product that utilizes annotation, signs, and symbols
        for site analysis in architecture would be a valuable tool for designers
        and architects. By providing a way to document and analyze site
        conditions in a clear and organized way, this product could streamline
        the design process and lead to more successful outcomes for clients and
        users.
        <br />
        a pack containing an AI file and JPEG of more than 100+ architecture
        annotations and symbols.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes following file format.
        <br />
        AI file
        <br />
        JPEG file
        <br />
        PNG files
        <br />
        Fonts
        <br />
        ABR brushes
        <br />
        textures png
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Project Resource Files",
    filetype: "ZIP file",
    images: [image76, image77],
  },
  {
    name: "SKETCHY TREES FREEBIE",
    price: "$0",
    link: "https://studioarticulation.gumroad.com/l/nhijd?layout=profile",
    size: "7.42 MB",
    description: (
      <>
        Introducing our SKETCHY TREE PACK , designed specifically for
        architecture students and related disciplines. This resource pack is an
        invaluable asset that offers numerous benefits and enhances the creative
        process in architectural design. SKETCHY TREES Pack empowers
        architecture students to elevate their design presentations, foster
        creativity, and develop a strong visual language. It offers convenience,
        consistency, and a rich array of design possibilities, making it an
        essential tool for those passionate about architectural illustration and
        creating impactful designs.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes following file format.
        <br />
        AI FILE
        <br />
        JPEG FILE
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Vegetation",
    filetype: "ZIP file",
    images: [image78, image78],
  },
  {
    name: "SKETCHY SITE ANALYSIS ANNOTATIONS PACK 2",
    price: "$0",
    link: "https://studioarticulation.gumroad.com/l/lxkhm?layout=profile",
    size: "12.9 MB",
    description: (
      <>
        Introducing our Sketchy Site Analysis Annotations Pack, the ultimate
        tool for architecture students and professionals alike! This
        comprehensive pack is designed to streamline the process of creating
        architecture graphics by providing a range of symbols, annotations, and
        arrows that are essential for effective site analysis.
        <br />
        Whether you're working on a project for class or tackling a real-world
        architectural design, our annotations pack has you covered. With a
        variety of symbols representing different elements such as trees,
        buildings, roads, and more, you can easily convey key features of a site
        with clarity and precision.
        <br />
        Our pack also includes a selection of arrows and directional markers,
        allowing you to illustrate movement patterns, sight lines, and other
        important spatial relationships within your site analysis. These arrows
        are fully customizable, so you can adjust their size, color, and style
        to suit your specific needs.
        <br />
        Available in AI, PNG, and JPEG formats, our annotations pack is
        compatible with a range of design software, ensuring seamless
        integration into your workflow. Whether you prefer to work in Adobe
        Illustrator, Photoshop, or any other design tool, you can easily
        incorporate our annotations into your graphics with ease.
        <br />
        Don't waste time creating site analysis graphics from scratch –
        streamline your workflow with our Sketchy Site Analysis Annotations Pack
        and bring your architectural designs to life with clarity and precision.
      </>
    ),
    category: "Project Resource Files",
    filetype: "ZIP file",
    images: [image79, image79],
  },
  {
    name: "Casual People Pack",
    price: "$4",
    link: "https://studioarticulation.gumroad.com/l/hdinn?layout=profile",
    size: "6.41 MB",
    description: (
      <>
        We are introducing our Vector-based casual people Pack, designed
        specifically for architecture students and related disciplines. This
        resource pack is an invaluable asset that offers numerous benefits and
        enhances the creative process in architectural design. The Casual People
        Pack empowers architecture students to elevate their design
        presentations, foster creativity, and develop a strong visual language.
        It offers convenience, consistency, and a rich array of design
        possibilities, making it an essential tool for those passionate about
        architectural illustration and creating impactful designs.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes in the following file format.
        <br />
        AI FILE
        <br />
        PNG FILE
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Humans",
    filetype: "ZIP file",
    images: [image80, image80],
  },
  {
    name: "RESIDENTIAL FURNITURE KIT",
    price: "$3",
    link: "https://studioarticulation.gumroad.com/l/jlzdo?layout=profile",
    size: "6.41 MB",
    description: (
      <>
        Discover Studio Articulation's uniquely crafted digital solution
        tailored for students and architects. Our CAD block collection offers a
        diverse range of meticulously designed elements, from architectural
        details to furniture pieces, aimed at enhancing workflow efficiency.
        With formats including CAD blocks, PNGs, and Illustrator files, users
        can seamlessly integrate our resources into their projects, enabling
        smoother planning processes. Whether refining floor plans or adding
        intricate details, our product ensures users can work with ease and
        precision, fostering creativity and productivity in architectural
        endeavors.
        <br />
        * The files are in a ZIP file. Make sure to update your Winrar or Winzip
        to extract the pack.
        <br />
        If you have any questions/suggestions, send us an e-mail:
        <br />
        its.riti.rathore@gmail.com
        <br />
        the pack comes in the following file format.
        <br />
        AI FILE
        <br />
        PNG FILE
        <br />
        CAD BLOCKS
        <br />
        thank you for supporting the platform @STUDIO_ARTICULATION
        <br />
        Note: while using our preset you can give credit to us. if you publish
        your work on Instagram or LinkedIn please tag us at @studio_articulation
      </>
    ),
    category: "Furniture",
    filetype: "ZIP file",
    images: [image81, image82],
  },
  {
    name: "ART OF ARCHITECTURE DIAGRAMMING by studio articulation",
    price: "$25",
    link: "https://studioarticulation.gumroad.com/l/eorqjh?layout=profile",
    size: "46.2 MB",
    description: (
      <>
        'Art of Architecture Diagramming' by Studio Articulation – your ultimate
        guide to mastering the art of visual storytelling in design.
        <br />
        🎨 Beginner-Friendly: Perfect for newcomers to architectural graphics,
        this eBook starts with the basics of Adobe Illustrator, tailored
        specifically to the needs of architecture students.
        <br />
        🌈 Color Palettes & Fonts: Learn the secrets of selecting captivating
        color schemes and fonts that enhance the visual impact of your diagrams,
        ensuring your presentations pop with personality and professionalism.
        <br />
        ✏️ Drawing & Diagram Styles: Explore a plethora of drawing and diagram
        styles, from concept and form development diagrams to sections,
        elevations, site analysis, spatial relationships analysis, and more.
        Each style is dissected and explained with clarity and enthusiasm.
        <br />
        📐 Practical Applications: Discover how to apply your newfound skills to
        real-world scenarios, including site zoning diagrams, isometric views,
        floor plan renders, and beyond. You'll be equipped to tackle any design
        challenge with confidence and creativity.
        <br />
        🚀 Elevate Your Presentations: Whether you're gearing up for a critique,
        presentation, or portfolio review, 'Art of Architecture Diagramming'
        empowers you to communicate your design ideas with clarity, conviction,
        and flair.
        <br />
        🔥 Unlock Your Creative Potential: Don't just showcase your designs –
        make them unforgettable. With 'Art of Architecture Diagramming,' you'll
        unleash your creativity and leave a lasting impression on professors,
        peers, and potential clients alike.
        <br />
        📚 Your Essential Companion: Compact yet comprehensive, this eBook is
        packed with practical tips, step-by-step tutorials, and inspiring
        examples to fuel your architectural journey from novice to virtuoso.
        <br />
        Transform your design presentations from ordinary to extraordinary. Get
        your hands on 'Art of Architecture Diagramming' today and embark on a
        visual adventure that will shape your future in architecture!"
        <br />
        content
        <br />
        introductions to basics
        <br />
        colour theory & typeface
        <br />
        site analysis diagram
        <br />
        site zoning diagram
        <br />
        spatial relationship diagram
        <br />
        concept development diagram
        <br />
        floor plan render diagram
        <br />
        illustrative section
        <br />
        illustrative elevation
        <br />
        isometric view
      </>
    ),
    category: "Project Resource Files",
    filetype: "ZIP file",
    images: [image84, image83],
  },
];
const architecturalElements = [
  {
    category: "Vegetation",
  },
  {
    category: "Human",
  },
  {
    category: "Patterns",
  },
  {
    category: "Furniture",
  },
  {
    category: "Texture",
  },
  {
    category: "CAD Blocks",
  },
  {
    category: "Symbols & Annotations",
  },
  {
    category: "Abr Brushes",
  },
  {
    category: "Project Resource Files",
  },
];

export { Shopdata, architecturalElements };
